/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-break-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M4 0h8a2 2 0 012 2v7H2V2a2 2 0 012-2M2 12h12v2a2 2 0 01-2 2H4a2 2 0 01-2-2zM.5 10a.5.5 0 000 1h15a.5.5 0 000-1z"/>',
    },
});
